/* .mainListingcard{
    width: min(100%, 400px);
} */

/* .mainListingcard{
    width: 100%;
} */

.mainListingcard{
    cursor: pointer;
}

.listingcardImg{
    width: 40%;
}

.listingcardData{
    width: 60%;
}

.listingcardRating{
    font-size: 12px;
}

.listingcardPrice{
    font-size: 13px;
}

.listingCardcategory{
    font-size: 12px;
}

/* @media screen and (min-width: 980px) {
    .mainListingcard{
        margin-right: 20px !important;
    }
} */