.mainProductCard{
    /* width: min(60%, 300px); */
    border-radius: 40px;
    /* overflow: hidden; */
    border-width: 2px !important;
    cursor: pointer;
}

.productName p{
    line-height: 18px !important;
    font-size: 18px;
}

.productCardRating i, .productCardRating p{
    font-size: 12px;
}

.productCardPrice p{
    font-size: 20px;
    width: max-content;
}

.active{
    margin-top: -25px;
    /* transition: 0.3s; */
}

.owl-stage{
    padding-top: 35px;
}