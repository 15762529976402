.bottomNav{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-radius: 20px !important;
    width: min(100%, 600px);
}

.bottomNavCont{
    position: fixed;
    bottom: 0px;
    z-index: 55;
    width: 100%;
    padding: 0px 10px;
}

.tabIcon{
    font-size: 20px;
    width: 30px;
    overflow: hidden;
    border-radius: 15px;
}

.activeNavTab{
    background-color: white;
    padding: 8px 15px !important;
    width: 110px;
    color: #808080;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: tabAnimation ease-in-out 0.5s;
}

@keyframes tabAnimation {
    from{
        width: 40px;
    }
    to{
        width: 110px;
    }
}

.svgWraper{
    width: 25px;
}

.svgWraper img{
    padding: 2px;
}

.svgWraper span{
    font-size: 17px;
}

.navTabSpan{
    font-size: 17px !important;
}

.homesvg{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg) brightness(102%) contrast(107%);
}

.svgicon{
    filter: invert(0%) sepia(99%) saturate(2%) hue-rotate(90deg) brightness(111%) contrast(100%);
}

.whaticon{
    filter: invert(99%) sepia(4%) saturate(0%) hue-rotate(79deg) brightness(117%) contrast(100%);
}

.svgiconActive{
    filter: invert(52%) sepia(3%) saturate(0%) hue-rotate(333deg) brightness(97%) contrast(93%);
}

.sliderWrap{
    padding: 10px !important;
}

/* .slick-list{
    padding-top: 40px !important;
} */

/* .slick-active{
    margin-top: -40px !important;
    transition: 0.5s !important;
} */

.extrasvg{
    font-size: 14px !important;
}

@media screen and (min-width: 900px) {
    .bottomNavCont{
        position: unset;
        width: 50%;
    }
}